<template>
    <div class="text-right mb-4">
        <button :title='$t(btn_title)' id="button_validation" class="btn btn-primary" @click="$emit('btn-onclick')" :disabled="disabled">
			<font-awesome-icon :icon="btn_icon" /> {{ $t(btn_title) }}
        </button>
    </div>
</template>

<script type="text/javascript">
	export default {
		props:[
            'title',
            'icon',
            'disabled'
		],
		data (){
			return {
                btn_title: "global.suivant",
                btn_icon : ['far', 'plus']
			}
		},
		mounted() {
			if(this.icon == 'ajouter') this.btn_icon = ['far', 'plus']
			else if(this.icon == 'suivant') this.btn_icon = ['far', 'arrow-right']

    		this.init_component()
		},
		methods: {
			async init_component() {
                if(this.title) {
                    this.btn_title = this.title
                }
			}
		}
	}
</script>
